import React from 'react';
import ContentPageLayout from '../components/ContentPageLayout';
import GeneralTerms from '../components/GeneralTerms';

const Page = () => (
  <ContentPageLayout title="Condiciones generales">
    <GeneralTerms />
  </ContentPageLayout>
);

export default Page;
